import {
    Component,
    CUSTOM_ELEMENTS_SCHEMA,
    HostListener,
    inject,
    OnInit,
} from '@angular/core';
import { ErrorCode } from 'dashboard/src/app/enums/shared';
import { SubscribableComponent } from 'ngx-subscribable';
import { getHost } from 'plmt-core-library';
import { TranslateModule } from '../../modules/translate/translate.module';
import { ErrorPageService } from './error-page.service';
import { WatchChanges } from 'ng-onpush';

export const messageForPreupdate = 'has no preupdate data access';
@Component({
    selector: 'app-error-page',
    standalone: true,
    imports: [TranslateModule],
    templateUrl: './error-page.component.html',
    styleUrls: ['./error-page.component.less'],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ErrorPageComponent
    extends SubscribableComponent
    implements OnInit
{
    private errorPageService = inject(ErrorPageService);

    readonly errorCode = ErrorCode;

    @WatchChanges()
    message = '';

    host = getHost();

    @HostListener('sl-request-close', ['$event'])
    preventDefault(event: Event) {
        event.preventDefault();
    }

    ngOnInit(): void {
        this.subscriptions = [
            this.errorPageService.errorData.subscribe((error) => {
                this.host.setAttribute('open', 'true');

                switch (true) {
                    case error.code === this.errorCode.linkExpired:
                        this.message = 'errorTimeLink';
                        break;
                    case error.message === messageForPreupdate:
                        this.message = 'hasNoPreupdateDataAccess';
                        break;

                    default:
                        this.message = 'errorLink';
                }
            }),
        ];
    }
}
