@if (message) {
    <div class="logo">
        <sl-icon library="core" name="polymatica-logo-medium"></sl-icon>
        <div class="logo-description">
            {{ 'passwordLogoDescription' | translate }}
        </div>
    </div>

    <footer>
        <div class="image">
            <img
                src="../../../../../assets/images/sharing-bg-grey.svg"
                alt="Полиматика"
            />
        </div>

        <div class="footer">
            <div class="footer-item">
                <span>En</span>
                <div class="copyright">
                    © 2011-2022, <span>{{ 'polymatica' | translate }}</span>
                </div>
            </div>
        </div>
    </footer>

    <sl-dialog
        label="{{ 'accessNotPossible' | translate }}"
        class="dialog-overview center"
        [open]="true"
    >
        {{ message | translate }}
    </sl-dialog>
}
